import { ROLES, PUBLIC_ROLES } from './users';

export const ROUTES = {
  HOME: {
    path: '/',
    basePath: '',
    exact: true,
    supportedRoles: PUBLIC_ROLES
  },
  LOGIN_USER: {
    path: '/login',
    basePath: '',
    publicRoute: true,
    exact: false
  },
  PASSWORD_RECOVERY: {
    path: '/recover',
    basePath: '',
    publicRoute: true,
    exact: false
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    basePath: '',
    publicRoute: true,
    exact: false
  },
  PARAMETRIZATION: {
    path: '/parametrization',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  PARAMETRIZATION_AUDIT: {
    path: '/parametrization_audit',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  CASE_AUDIT: {
    path: '/case_audit/:id',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  USERS_LIST: {
    path: '/users',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  USER_CREATION: {
    path: '/user-creation',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  USER_AUDIT: {
    path: '/user/:id/audit',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: true
  },
  USER_EDITION: {
    path: '/user/:id',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: true
  },
  CASE_CREATION: {
    path: '/case-creation',
    basePath: '',
    supportedRoles: PUBLIC_ROLES,
    exact: false
  },
  CASE_VISUALIZATION: {
    path: '/case/:id',
    basePath: '',
    supportedRoles: PUBLIC_ROLES,
    exact: true
  },
  CASE_REPORTS_AUDIT: {
    path: '/case/:id/reports',
    basePath: '',
    supportedRoles: [ROLES.ADMIN]
  },
  CASE_INTERVENTIONS: {
    path: '/case/:id/interventions',
    basePath: '',
    supportedRoles: [ROLES.PROFESSIONAL, ROLES.ADMIN, ROLES.SUPERVISOR],
    exact: true
  },
  CASE_INTERVENTION: {
    path: '/case/:id/interventions/:interventionId',
    basePath: '',
    supportedRoles: [ROLES.PROFESSIONAL, ROLES.ADMIN, ROLES.SUPERVISOR],
    exact: true
  },
  CASE_INTERVENTION_CREATION: {
    path: '/case/:id/intervention-creation',
    basePath: '',
    supportedRoles: [ROLES.PROFESSIONAL, ROLES.ADMIN, ROLES.SUPERVISOR]
  },
  CASE_EDITION: {
    path: '/case/:id/edit',
    basePath: '',
    supportedRoles: PUBLIC_ROLES
  },
  RISK_MANAGEMENT: {
    path: '/risk-management',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  RISK_AUDIT: {
    path: '/risk_audit',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  REPORTS: {
    path: '/reports',
    basePath: '',
    supportedRoles: [ROLES.ADMIN, ROLES.SUPERVISOR],
    exact: false
  },
  REPORTS_AUDIT: {
    path: '/reports_audit',
    basePath: '',
    supportedRoles: [ROLES.ADMIN],
    exact: false
  },
  CASE_SEARCH: {
    path: '/cases',
    basePath: '',
    supportedRoles: PUBLIC_ROLES,
    exact: false
  }
};
