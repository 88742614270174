import i18next from 'i18next';
import JsPDF from 'jspdf';

import { buildDateES } from '~utils/date';

import { FONT_SIZE } from '../constants';

import { addFooterToPage, getSplittedTexts, mapInterventionsToTexts, writeTexts } from './utils';

export const createReportPDF = (
  aCase,
  { to, position, institution, labeleds, title, comments, introduction }
) => {
  const pdf = new JsPDF('p', 'mm');

  const commentsObject = comments
    ? {
        commentsTitle: i18next.t('CaseReport:commentsTitle'),
        comments
      }
    : {};
  pdf.setFontSize(FONT_SIZE);
  const splittedTexts = getSplittedTexts(
    {
      labeleds: (labeleds && i18next.t('CaseReport:labeleds', { labeleds })) || '',
      date: buildDateES(new Date()),
      to,
      position,
      institution,
      title: title || 'Reporte de Caso',
      space: '\n',
      introduction: introduction || i18next.t('CaseReport:introduction'),
      interventionsTitle: i18next.t('CaseReport:interventionsTitle'),
      ...mapInterventionsToTexts(aCase.interventions),
      ...commentsObject
    },
    pdf
  );

  writeTexts(splittedTexts, pdf);

  addFooterToPage(pdf);
  return pdf.save(`${i18next.t('CaseReport:fileName')}.pdf`);
};
