import i18next from 'i18next';

i18next.addResources('es', 'Menu', {
  parametrization: 'Parametrización',
  line144: 'Polo de la mujer',
  riskAnalysis: 'Análisis de riesgo',
  users: 'Usuarios/as',
  reports: 'Reportes',
  caseCreation: 'Formulario de alta',
  caseSearch: 'Búsqueda de casos',
  userCreation: 'Creación de usuarios/as',
  userList: 'Listado de usuarios/as'
});
