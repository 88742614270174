import { createTypes, completeTypes } from 'redux-recompose';

import sectionsService from '../../services/Sections/service';

export const actions = createTypes(completeTypes(['GET_RISKS_AUDIT'], []), '@@RISKS');

const targets = {
  AUDIT: 'audit'
};

export const actionCreators = {
  getRisksAudit: () => ({
    type: actions.GET_RISKS_AUDIT,
    target: targets.AUDIT,
    service: sectionsService.getRisksAudit
  })
};

export default actionCreators;
