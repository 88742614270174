import i18next from 'i18next';

i18next.addResources('es', 'InterventionCreation', {
  formTitle: 'Crear Intervención',
  required: 'Requerido',
  creationSuccess: 'Se creó la intervención exitosamente.',
  uploadFileFailure: 'Hubo un error al subir el archivo seleccionado.',
  deleteFileFailure: 'Hubo un error eliminando el archivo seleccionado.',
  creationFailure: 'Hubo un error al crear la intervención.',
  isLeaving: 'Tenés cambios de la intervención sin guardar. ¿Estás seguro que querés irte?',
  title: 'Título',
  description: 'Descripción',
  files: 'Archivos',
  type: 'Arista',
  legal: 'Legal',
  economic: 'Económica',
  social: 'Social',
  upload: 'Subir Archivo',
  sanitary: 'Sanitaria',
  psychologic: 'Psicológica'
});
