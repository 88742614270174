import loading from './loading.json';
import { replaceColor } from './utils';

const DEFAULT_COLOR = [0.016, 0.416, 0.816];

export const SPINNER_TYPES = {
  loading
};

export const getOptions = ({ type, loop = true, autoplay = true, rendererSettings, color = '' }) => {
  const animationData = SPINNER_TYPES[type] || SPINNER_TYPES.loading;
  return {
    loop,
    autoplay,
    animationData: color ? replaceColor(DEFAULT_COLOR, color, animationData) : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      ...rendererSettings
    }
  };
};
