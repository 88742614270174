import i18next from 'i18next';

i18next.addResources('es', 'ReportChart', {
  casesPerDayTitle: 'Reporte de casos del Polo de la Mujer',
  cases: 'casos',
  totalCases: 'Casos totales: {{total}}',
  totalCasesPerDay: 'Casos totales en el período: {{total}}',
  simpleFieldTitle: 'Casos Simples',
  multipleFieldTitle: 'Casos Multiples',
  chartValue: "{{percentage}}% $t(ReportChart:amountOfCases, { 'count': {{count}} })",
  amountOfCases: '{{count}} caso',
  amountOfCases_plural: '{{count}} casos',
  download: 'Descargar',
  nonFoundCases: 'No se encontraron casos en el período.'
});
