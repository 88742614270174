import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import { downloadFileFrom } from '~utils/file';

import FileService from '../../services/FileService';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(['UPLOAD_FILE', 'DELETE_FILE', 'DOWNLOAD_FILE'], ['ADD_FILE', 'REMOVE_FILE', 'CLEAR_FILES']),
  '@@S3'
);

export const actionCreators = {
  createFile: file => ({
    type: actions.UPLOAD_FILE,
    target: TARGETS.UPLOAD_FILE,
    payload: file,
    service: FileService.uploadFileToS3,
    injections: [
      withPostSuccess((dispatch, response) => dispatch(actionCreators.addFile(response.fileUploaded))),
      withPostFailure(() => toast.error(i18next.t('InterventionCreation:uploadFileFailure')))
    ]
  }),
  deleteFile: file => ({
    type: actions.DELETE_FILE,
    target: TARGETS.DELETE_FILE,
    payload: file,
    service: FileService.deleteFileFromS3,
    injections: [
      withPostSuccess(dispatch => dispatch(actionCreators.removeFile(file))),
      withPostFailure(() => toast.error(i18next.t('InterventionCreation:deleteFileFailure')))
    ]
  }),
  downloadFile: file => ({
    type: actions.DOWNLOAD_FILE,
    target: TARGETS.DOWNLOAD_FILE,
    payload: file,
    service: FileService.getFileFromS3,
    injections: [withPostSuccess((_, response) => downloadFileFrom(response.data, file))]
  }),
  clearFiles: () => ({
    type: actions.CLEAR_FILES,
    target: TARGETS.FILES
  }),
  removeFile: file => ({
    type: actions.REMOVE_FILE,
    target: TARGETS.FILES,
    payload: file
  }),
  addFile: file => ({
    type: actions.ADD_FILE,
    target: TARGETS.FILES,
    payload: file
  })
};

export default actionCreators;
