import i18next from 'i18next';

i18next.addResources('es', 'Section', {
  addField: '+ Agregar campo',
  name: 'Nombre',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  updateSuccess: 'Se guardó la sección exitosamente.',
  updateError: 'Hubo un error con el guardado de la sección.',
  hasChangedSections: 'Tenés cambios sin guardar. ¿Estás seguro que querés irte?',
  fieldName: 'Nombre del campo',
  optionPlaceholder: 'Ingrese la respuesta...'
});
