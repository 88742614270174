import immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';
import onReadValue from 'redux-recompose/lib/effects/onReadValue';
import onSetValue from 'redux-recompose/lib/effects/onSetValue';

import { actions } from './actions';
import { TARGETS } from './constants';

const initialState = {
  [TARGETS.UPLOAD_FILE]: null,
  [TARGETS.DELETE_FILE]: null,
  [TARGETS.FILES]: []
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [actions.UPLOAD_FILE, actions.DELETE_FILE],
  override: {
    [actions.CLEAR_FILES]: onSetValue([]),
    [actions.ADD_FILE]: onReadValue((action, state) => [...state.files, action.payload]),
    [actions.REMOVE_FILE]: onReadValue((action, state) => state.files.filter(file => file !== action.payload))
  }
};

const reducer = createReducer(immutable(completedState), completeReducer(reducerDescription));

export default reducer;
