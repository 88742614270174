import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  createUser: null,
  updatePassword: null,
  users: { users: [], maxPages: null },
  professionals: {},
  user: {},
  audit: [],
  updateUser: null,
  deleteUser: null,
  forgotPassword: null
});

const reducerDescription = {
  primaryActions: [
    actions.CREATE_USER,
    actions.ADMIN_CREATE_USER,
    actions.UPDATE_PASSWORD,
    actions.GET_USERS,
    actions.GET_PROFESSIONALS,
    actions.GET_USER,
    actions.GET_USER_AUDIT,
    actions.UPDATE_USER,
    actions.DELETE_USER,
    actions.FORGOT_PASSWORD
  ]
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
