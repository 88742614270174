import React from 'react';
import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-day-picker/lib/style.css';
import store from '~redux/store';

import CoreSettingsWrapper from './components/CoreSettingsWrapper';
import Routes from './components/Routes';

const App = () => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <CoreSettingsWrapper>
        <Routes />
        <ToastContainer />
      </CoreSettingsWrapper>
    </Provider>
  </DndProvider>
);

export default hot(App);
