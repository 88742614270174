import i18next from 'i18next';

i18next.addResources('es', 'Risk', {
  notNumberError: 'Ingresar un número.',
  notIntegerError: 'Ingresar un número entero.',
  notPositiveError: 'El número ingresado debe ser positivo.',
  valueRequired: 'Requerido.',
  lessThanMediumError: 'El valor ingresado debe ser menor al riesgo medio.',
  lessThanHighError: 'El valor ingresado debe ser menor al riesgo alto.',
  lessThanVeryhighError: 'El valor ingresado debe ser menor al riesgo Muy Alto.',
  low: 'Bajo',
  medium: 'Medio',
  high: 'Alto',
  veryHigh: 'Muy Alto',
  title: 'Niveles de Riesgos',
  isLeaving: 'Hay niveles de riesgo editados sin guardar. ¿Estás seguro que querés irte?'
});
