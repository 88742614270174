import { push } from 'connected-react-router';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import { ROUTES } from '~constants/routes';
import sectionsActions from '~redux/Sections/actions';
import casesService from '~services/Cases/service';
import { createReportPDF } from '~services/pdfs/CaseReport/service';
import reportsService from '~services/Reports/service';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';

import filesActions from '../S3/actions';

import { updateStateCaseAction } from './utils';

export const actions = createTypes(
  completeTypes(
    [
      'CREATE_CASE',
      'GET_CASES',
      'GET_CASE',
      'GET_CASE_AUDIT',
      'GET_CASE_REPORT_AUDIT',
      'CREATE_CASE_REPORT_AUDIT',
      'GET_REPORT',
      'GET_REPORT_AUDIT',
      'GET_CASE_INTERVENTIONS',
      'GET_CASE_INTERVENTION',
      'UPDATE_CASE',
      'UPDATE_CASE_NOTES',
      'UPDATE_CASE_PROFESSIONALS',
      'CHANGE_CASE_STATE',
      'UNASSIGN_FROM_CASE',
      'CREATE_CASE_INTERVENTION'
    ],
    ['CLEAR_CASES', 'CLEAR_REPORT']
  ),
  '@@CASES'
);

export const targets = {
  CASES: 'cases',
  CASE: 'case',
  AUDIT: 'audit',
  REPORT: 'report',
  REPORT_AUDIT: 'reportAudit',
  GET_CASE_REPORT_AUDIT: 'caseReportAudit',
  CREATE_CASE_REPORT_AUDIT: 'createCaseReportAudit',
  INTERVENTIONS: 'interventions',
  INTERVENTION: 'intervention',
  CREATE_CASE: 'createCase',
  UPDATE_CASE: 'updateCase',
  UPDATE_CASE_NOTES: 'updateCaseNotes',
  UPDATE_CASE_PROFESSIONALS: 'updateCaseProfessionals',
  CHANGE_CASE_STATE: 'changeCaseState',
  UNASSIGN_FROM_CASE: 'unassignFromCase',
  CREATE_CASE_INTERVENTION: 'createCaseIntervention'
};

const actionCreators = {
  createCase: aCase => ({
    type: actions.CREATE_CASE,
    target: targets.CREATE_CASE,
    service: casesService.createCase,
    payload: ({
      auth: {
        currentUser: {
          user: { id }
        }
      }
    }) => ({ operator: id, sections: aCase }),
    injections: [
      withPostSuccess((dispatch, response) => {
        if (response?.data?.caseId) {
          dispatch(push(ROUTES.CASE_VISUALIZATION.path.replace(':id', response?.data?.caseId)));
        } else {
          dispatch(sectionsActions.getSections());
        }

        return toast.success(i18next.t('CaseCreation:updateSuccess'));
      }),
      withPostFailure(() => toast.error(i18next.t('CaseCreation:updateError')))
    ]
  }),
  createCaseIntervention: ({ id, intervention }) => ({
    type: actions.CREATE_CASE_INTERVENTION,
    target: targets.CREATE_CASE_INTERVENTION,
    service: casesService.createCaseIntervention,
    payload: { id, intervention },
    injections: [
      withPostSuccess(dispatch => {
        toast.success(i18next.t('InterventionCreation:creationSuccess'));
        dispatch(filesActions.clearFiles());
        dispatch(push(ROUTES.CASE_INTERVENTIONS.path.replace(':id', id)));
      }),
      withPostFailure(() => toast.error(i18next.t('InterventionCreation:creationFailure')))
    ]
  }),
  getCaseInterventions: query => ({
    type: actions.GET_CASE_INTERVENTIONS,
    target: targets.INTERVENTIONS,
    payload: query,
    service: casesService.getInterventions,
    injections: [withPostFailure(() => toast.error(i18next.t('InterventionsList:fetchError')))]
  }),
  getCaseIntervention: ids => ({
    type: actions.GET_CASE_INTERVENTION,
    target: targets.INTERVENTION,
    payload: ids,
    service: casesService.getIntervention,
    injections: [withPostFailure(() => toast.error(i18next.t('InterventionVisualization:fetchError')))]
  }),
  getReportAudit: () => ({
    type: actions.GET_REPORT_AUDIT,
    target: targets.REPORT_AUDIT,
    service: reportsService.getReportAudit
  }),
  getCase: (id, { shouldPopulate, shouldHaveInterventions } = {}) => ({
    type: actions.GET_CASE,
    target: targets.CASE,
    service: casesService.getCase,
    payload: { id, shouldPopulate, shouldHaveInterventions },
    injections: [withPostFailure(() => toast.error(i18next.t('Cases:fetchSingleError')))]
  }),
  getCaseAudit: id => ({
    type: actions.GET_CASE_AUDIT,
    target: targets.AUDIT,
    service: casesService.getCaseAudit,
    payload: id
  }),
  getCaseReportAudit: id => ({
    type: actions.GET_CASE_REPORT_AUDIT,
    target: targets.GET_CASE_REPORT_AUDIT,
    service: casesService.getCaseReportAudit,
    payload: id
  }),
  createCaseReportAudit: (aCase, report) => ({
    type: actions.CREATE_CASE_REPORT_AUDIT,
    target: targets.CREATE_CASE_REPORT_AUDIT,
    service: casesService.createCaseReportAudit,
    payload: { id: aCase.id, report },
    injections: [
      withPostSuccess(dispatch => {
        createReportPDF(aCase, report);
        dispatch(ModalActions.closeModal(MODALS.CREATE_CASE_REPORT));
      })
    ]
  }),
  createReport: (query, selectedFields, datesRange) => ({
    type: actions.GET_REPORT,
    target: targets.REPORT,
    service: reportsService.createReport,
    payload: { query, selectedFields, datesRange },
    injections: [withPostFailure(() => toast.error(i18next.t('ReportCreation:creationFailed')))]
  }),
  getCases: query => ({
    type: actions.GET_CASES,
    target: targets.CASES,
    service: casesService.getCases,
    payload: query,
    injections: [withPostFailure(() => toast.error(i18next.t('Cases:fetchError')))]
  }),
  updateCase: newCase => ({
    type: actions.UPDATE_CASE,
    target: targets.UPDATE_CASE,
    service: casesService.updateCase,
    payload: newCase,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.getCase(newCase.id));
        return toast.success(i18next.t('Cases:updateSuccess'));
      }),
      withPostFailure(() => toast.error(i18next.t('Cases:updateError')))
    ]
  }),
  updateCaseNotes: updateStateCaseAction(
    actions.UPDATE_CASE_NOTES,
    targets.UPDATE_CASE_NOTES,
    casesService.updateCaseNotes
  ),
  updateCaseProfessionals: updateStateCaseAction(
    actions.UPDATE_CASE_PROFESSIONALS,
    targets.UPDATE_CASE_PROFESSIONALS
  ),
  changeCaseState: updateStateCaseAction(actions.CHANGE_CASE_STATE, targets.CHANGE_CASE_STATE),
  unassignUserFromCase: updateStateCaseAction(actions.UNASSIGN_FROM_CASE, targets.UNASSIGN_FROM_CASE),
  clearCases: () => ({
    type: actions.CLEAR_CASES,
    target: targets.CASES
  }),
  clearReport: () => ({
    type: actions.CLEAR_REPORT,
    target: targets.REPORT
  })
};

export default actionCreators;
