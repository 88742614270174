import i18next from 'i18next';

i18next.addResources('es', 'Audit', {
  view: 'Ver',
  createdAt: 'Fecha de edición',
  author: 'Autor/a',
  actions: 'Acciones',
  section: 'Sección',
  field: 'Campo',
  prevValue: 'Valor Previo',
  newValue: 'Valor Nuevo',
  diffStatus: 'Estado del Cambio',
  added: 'Añadido',
  changed: 'Cambiado',
  deleted: 'Eliminado',
  back: 'Atrás',
  false: 'Falso',
  true: 'Verdadero'
});

i18next.addResources('es', 'AuditKeys', {
  name: 'Nombre',
  surname: 'Apellido',
  email: 'Email',
  password: 'Contraseña',
  dni: 'DNI',
  role: 'Rol',
  active: 'Activo',
  deleted: 'Eliminado',
  passwordRecoveryToken: 'Token de recuperación de contraseña',
  fields: 'Campos',
  required: 'Requerido',
  nonDeletable: 'No eliminable',
  type: 'Tipo',
  options: 'Opciones',
  low: 'Bajo',
  medium: 'Medio',
  high: 'Alto',
  veryHigh: 'Muy Alto',
  value: 'Valor',
  riskValue: 'Nivel de Riesgo',
  createdAt: 'Fecha de Creación',
  updatedAt: 'Fecha de Actualización',
  sections: 'Secciones',
  operator: 'Operador/a',
  professionals: 'Profesionales',
  notes: 'Notas',
  state: 'Estado',
  interventions: 'Intervenciones',
  sectionId: 'ID de sección',
  fieldId: 'ID de campo',
  answer: 'Respuesta',
  selected: 'Seleccionado',
  title: 'Título',
  description: 'Descripción',
  files: 'Archivos',
  user: 'Usuario'
});

i18next.addResources('es', 'AuditEnums', {
  free: 'Respuesta libre',
  multiple: 'Respuesta múltiple',
  simple: 'Respuesta Simple',
  date: 'Respuesta de Fecha',
  time: 'Respuesta de Horario',
  numeric: 'Respuesta Numérica',
  operator: 'Operador/a',
  admin: 'Administrador/a',
  supervisor: 'Supervisor/a',
  professional: 'Profesional',
  pending: 'Pendiente',
  open: 'Abierto',
  closed: 'Cerrado',
  created: 'Creado',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  legal: 'Legal',
  economic: 'Económica',
  social: 'Social',
  sanitary: 'Sanitaria',
  psychologic: 'Psicológica'
});
