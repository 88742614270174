import { completeTypes, createTypes, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';
import withPostFailure from 'redux-recompose/lib/injections/withPostFailure';

import { setAuthHeader, removeAuthHeader } from '~config/api';
import * as AuthService from '~services/AuthService';
import LocalStorage from '~services/LocalStorageService';
import { ROUTES } from '~constants/routes';

import { MAIL_SENT } from './constants';

export const actions = createTypes(
  completeTypes(['SIGN_IN'], ['LOG_OUT', 'SET_USER', 'GET_CURRENT_USER']),
  '@@AUTH'
);

const targets = {
  SIGN_IN: 'signIn',
  LOG_OUT: 'logOut',
  CURRENT_USER: 'currentUser'
};

export const actionCreators = {
  signIn: ({ user, setCode }) => ({
    type: actions.SIGN_IN,
    target: targets.SIGN_IN,
    payload: user,
    service: AuthService.signIn,
    injections: [
      withPostSuccess((dispatch, response) => {
        if (response.status === MAIL_SENT) {
          setCode(response.status);
        } else {
          setAuthHeader(response.data.token);
          LocalStorage.setTokenManager(response.data);
          dispatch(actionCreators.getCurrentUser());
          dispatch(push(ROUTES.HOME.path));
        }
      })
    ]
  }),
  getCurrentUser: () => ({
    type: actions.GET_CURRENT_USER,
    target: targets.CURRENT_USER,
    service: AuthService.getCurrentUser,
    injections: [
      withPostFailure(() => {
        LocalStorage.removeTokenManager();
      })
    ]
  }),
  logOut: () => dispatch => {
    removeAuthHeader();
    LocalStorage.removeTokenManager();
    dispatch({
      type: actions.LOG_OUT,
      target: targets.LOG_OUT
    });
    dispatch(push(ROUTES.LOGIN_USER.path));
  }
};

export default actionCreators;
