import api, { createApiWithURL } from '~config/api';

const getSignedUrl = (filename, extension, action) =>
  api.post('/files/signed_url', {
    name: filename,
    extension,
    action
  });

const splitFileName = file => file.split('.');

const createSignedUrlWith = (file, action = '') => {
  const [filename, extension] = splitFileName(file.name || file);
  return getSignedUrl(filename, extension, action);
};

const uploadFileToS3 = file =>
  createSignedUrlWith(file)
    .then(async signedResponse => {
      const payload = signedResponse.data?.s3Data;

      const formData = new FormData();

      Object.keys(payload.fields).forEach(field => {
        formData.append(field, payload.fields[field]);
      });

      formData.append('file', file);
      const fileUploadResponse = await createApiWithURL(payload.url).post('', formData, {
        headers: { 'Content-type': 'multipart/form-data' }
      });
      return { fileUploadResponse, payload };
    })
    .then(({ fileUploadResponse, payload }) => ({
      ok: true,
      data: fileUploadResponse,
      fileUploaded: payload?.fields?.key
    }))
    .catch(err => ({ data: err }));

const getFileFromS3 = file => createSignedUrlWith(file, 'get');

const deleteFileFromS3 = file => api.delete('/files', { file });

export default {
  uploadFileToS3,
  deleteFileFromS3,
  getFileFromS3
};
