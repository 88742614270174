import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTES } from '~constants/routes';

import MenuItem from './components/MenuItem';
import { MENU_ITEMS } from './constants';
import styles from './styles.module.scss';

function Menu() {
  const { currentUser } = useSelector(state => state.auth);
  const { settings } = useSelector(state => state.settings);
  const shouldShowMenu = item => {
    const roles = item?.subMenus?.flatMap(sub => sub.route?.supportedRoles) || item.route?.supportedRoles;
    return currentUser && roles?.includes(currentUser?.user?.role);
  };
  return (
    <div className={styles.sideBarContainer}>
      <Link to={ROUTES.HOME.path}>
        <img alt="logo" src={settings.logo} className={styles.logo} />
      </Link>
      {MENU_ITEMS.map(item => shouldShowMenu(item) && <MenuItem key={item.name} item={item} />)}
    </div>
  );
}
export default Menu;
