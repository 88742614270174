import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onSetValue } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  onInitialLoad: true,
  signIn: null,
  logOut: null,
  currentUser: null
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [actions.SIGN_IN, actions.GET_CURRENT_USER],
  override: {
    [actions.LOGOUT]: onSetValue(null)
  }
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
