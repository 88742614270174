import api from '~config/api';

const getSections = () => api.get('/sections');

const getAudit = () => api.get('/sections/audit');

const updateSections = sections => api.put('/sections', sections);

const updateRisks = sectionsWithRisks => api.put('/risks', sectionsWithRisks);

const getRisksAudit = () => api.get('/risks/audit');

export default {
  getSections,
  getAudit,
  getRisksAudit,
  updateSections,
  updateRisks
};
