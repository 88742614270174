import { string } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const CustomHelmet = ({ title, description, favicon }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
  </Helmet>
);

CustomHelmet.propTypes = {
  description: string,
  favicon: string,
  title: string
};

export default CustomHelmet;
