import api from '~config/api';
import { getQueryString } from '~utils/parseUrl';

const createUser = user => api.post('/users', user);

const forgotPassword = email => api.post('/users/forgot_password', { email });

const updatePassword = password => api.post('/users/password', password);

const adminCreateUser = user => api.post('/admin/users', user);

const getUsers = users => api.get(`/admin/users${getQueryString({ users })}`);

const getProfessionals = () => api.get('/professionals');

const getUser = id => api.get(`/admin/users/${id}`);

const getUserAudit = id => api.get(`/admin/users/${id}/audit`);

const updateUser = ({ id, ...user }) => api.put(`/admin/users/${id}`, user);

const deleteUser = id => api.delete(`/admin/users/${id}`);

export default {
  createUser,
  updatePassword,
  adminCreateUser,
  getUsers,
  getUserAudit,
  getProfessionals,
  getUser,
  updateUser,
  deleteUser,
  forgotPassword
};
