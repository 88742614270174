import { bool, oneOf, shape, string } from 'prop-types';

import { ROLES } from '~constants/users';

export const routePropTypes = shape({
  path: string.isRequired,
  basePath: string,
  publicRoute: bool,
  exact: bool,
  supportedRoles: oneOf(Object.values(ROLES))
});
