import api from '~config/api';
import { getQueryString } from '~utils/parseUrl';

const createReport = ({ query, selectedFields, datesRange }) =>
  api.post(`/reports${getQueryString(query)}`, { selectedFields, datesRange });

const getReportAudit = () => api.get('/reports');

export default {
  createReport,
  getReportAudit
};
