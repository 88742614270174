import immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  audit: []
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [actions.GET_RISKS_AUDIT]
};

const reducer = createReducer(immutable(completedState), completeReducer(reducerDescription));

export default reducer;
