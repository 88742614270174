import { ROUTES } from '~constants/routes';
import SettingsIconOn from '~assets/sidebar/settings-on.svg';
import SettingsIconOff from '~assets/sidebar/settings-off.svg';
import Line144IconOn from '~assets/sidebar/polo-on.svg';
import Line144IconOff from '~assets/sidebar/polo-off.svg';
import RiskAnalysisIconOn from '~assets/sidebar/risk-on.svg';
import RiskAnalysisIconOff from '~assets/sidebar/risk-off.svg';
import UsersIconOn from '~assets/sidebar/users-on.svg';
import UsersIconOff from '~assets/sidebar/users-off.svg';
import ReportsIconOn from '~assets/sidebar/reports-on.svg';
import ReportsIconOff from '~assets/sidebar/reports-off.svg';
import SearchIconOn from '~assets/sidebar/search-on.svg';
import SearchIconOff from '~assets/sidebar/search-off.svg';
import CreateIconOn from '~assets/sidebar/create-on.svg';
import CreateIconOff from '~assets/sidebar/create-off.svg';
import CreateUsersIconOn from '~assets/sidebar/create-user-on.svg';
import CreateUsersIconOff from '~assets/sidebar/create-user-off.svg';
import UserListIconOn from '~assets/sidebar/user-list-on.svg';
import UserListIconOff from '~assets/sidebar/user-list-off.svg';

export const MENU_ITEMS = [
  {
    name: 'parametrization',
    iconOn: SettingsIconOn,
    iconOff: SettingsIconOff,
    route: ROUTES.PARAMETRIZATION
  },
  {
    name: 'line144',
    iconOn: Line144IconOn,
    iconOff: Line144IconOff,
    subMenus: [
      {
        name: 'caseCreation',
        iconOn: CreateIconOn,
        iconOff: CreateIconOff,
        route: ROUTES.CASE_CREATION
      },
      {
        name: 'caseSearch',
        iconOn: SearchIconOn,
        iconOff: SearchIconOff,
        route: ROUTES.CASE_SEARCH
      }
    ]
  },
  {
    name: 'riskAnalysis',
    iconOn: RiskAnalysisIconOn,
    iconOff: RiskAnalysisIconOff,
    route: ROUTES.RISK_MANAGEMENT
  },
  {
    name: 'users',
    iconOn: UsersIconOn,
    iconOff: UsersIconOff,
    subMenus: [
      {
        name: 'userCreation',
        iconOn: CreateUsersIconOn,
        iconOff: CreateUsersIconOff,
        route: ROUTES.USER_CREATION
      },
      {
        name: 'userList',
        iconOn: UserListIconOn,
        iconOff: UserListIconOff,
        route: ROUTES.USERS_LIST
      }
    ]
  },
  {
    name: 'reports',
    iconOn: ReportsIconOn,
    iconOff: ReportsIconOff,
    route: ROUTES.REPORTS
  }
];
