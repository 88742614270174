import i18next from 'i18next';

i18next.addResources('es', 'UserEdition', {
  getUserError: 'Hubo un error al obtener el usuario.',
  delete: 'Eliminar',
  deleteUserSuccess: 'Se eliminó al usuario correctamente.',
  deleteUserError: 'Hubo un error eliminando al usuario.',
  updateUserError: 'Hubo un error en la actualización del usuario.',
  updateUserErrorCode1031: 'Ya hay un usuario con el email ingresado. Por favor, ingresar otro.',
  updateUserSuccess: 'Se actualizó correctamente el usuario.'
});
