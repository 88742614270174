import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onSetValue } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  cases: { cases: null, maxPages: null },
  case: {},
  report: {},
  reportAudit: {},
  caseReportAudit: {},
  audit: [],
  interventions: { interventions: null, maxPages: null },
  intervention: {},
  createCase: null,
  createCaseIntervention: null,
  updateCase: null,
  updateCaseProfessionals: null,
  changeCaseState: null,
  unassignFromCase: null
});

const reducerDescription = {
  primaryActions: [
    actions.CREATE_CASE,
    actions.GET_CASES,
    actions.GET_CASE,
    actions.GET_CASE_AUDIT,
    actions.GET_CASE_REPORT_AUDIT,
    actions.CREATE_CASE_REPORT_AUDIT,
    actions.GET_REPORT_AUDIT,
    actions.GET_CASE_INTERVENTIONS,
    actions.GET_CASE_INTERVENTION,
    actions.UPDATE_CASE,
    actions.GET_REPORT,
    actions.UPDATE_CASE_PROFESSIONALS,
    actions.CHANGE_CASE_STATE,
    actions.UNASSIGN_FROM_CASE,
    actions.CREATE_CASE_INTERVENTION
  ],
  override: {
    [actions.CLEAR_CASES]: onSetValue([]),
    [actions.CLEAR_REPORT]: onSetValue({})
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
