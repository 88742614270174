import i18next from 'i18next';

i18next.addResources('es', 'ForgotPasswordForm', {
  email: 'Email',
  submit: 'Olvidé mi contraseña'
});

i18next.addResources('es', 'ForgotPasswordFormErrors', {
  required: 'Requerido',
  invalidEmail: 'Email inválido'
});
