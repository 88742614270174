import api from '~config/api';
import { getQueryString } from '~utils/parseUrl';

const createCase = values => api.post('/cases', values);

const getCases = cases => api.get(`/cases${getQueryString({ cases })}`);

const getCase = ({ id, shouldPopulate, shouldHaveInterventions }) =>
  api.get(`/cases/${id}`, { shouldPopulate, shouldHaveInterventions });

const getCaseAudit = id => api.get(`/cases/${id}/audit`);

const getCaseReportAudit = id => api.get(`/cases/${id}/reports`);

const createCaseReportAudit = ({ id, report }) => api.post(`/cases/${id}/reports`, { ...report });

const updateCase = ({ id, case: newCase }) => api.put(`/cases/${id}`, newCase);

const updateStateCase = ({ id, state: newState }) => api.put(`/cases/${id}/states`, newState);

const updateCaseNotes = ({ id, notes }) => api.put(`/cases/${id}/notes`, { notes });

const createCaseIntervention = ({ id, intervention }) => api.post(`/cases/${id}/interventions`, intervention);

const getInterventions = ({ id, query }) =>
  api.get(`/cases/${id}/interventions${getQueryString({ interventions: query })}`);

const getIntervention = ({ caseId, interventionId }) =>
  api.get(`/cases/${caseId}/interventions/${interventionId}`);

export default {
  createCase,
  getCases,
  getCase,
  getCaseAudit,
  getCaseReportAudit,
  createCaseReportAudit,
  updateCase,
  updateCaseNotes,
  updateStateCase,
  createCaseIntervention,
  getInterventions,
  getIntervention
};
