import { create } from 'apisauce';
import { CamelcaseSerializer, SnakecaseSerializer } from 'cerealizr';
import { ROUTES } from '~constants/routes';

import { NON_SERIALIZABLE_URLS } from '~constants/urls';
import LocalStorage from '~services/LocalStorageService';

const camelSerializer = new CamelcaseSerializer();
const snakeSerializer = new SnakecaseSerializer();

const notSerializableUrl = url => NON_SERIALIZABLE_URLS.includes(url);

export const createApiWithURL = baseURL =>
  create({
    baseURL,
    timeout: 15000
  });

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000
});

export const headers = {
  AUTHORIZATION: 'Authorization'
};

export const setAuthHeader = token => api.setHeader(headers.AUTHORIZATION, `Bearer ${token}`);

export const removeAuthHeader = () => api.deleteHeader(headers.AUTHORIZATION);

const authorizeRequest = request => {
  if (!request.headers[headers.AUTHORIZATION]) {
    const tokenManager = LocalStorage.getTokenManager();
    if (tokenManager) {
      setAuthHeader(tokenManager.token);
    }
  }
};

const serializeRequest = (serializer, request) => ({ ...request, data: serializer.serialize(request.data) });

api.addResponseTransform(response => {
  if (response.data && !notSerializableUrl(response.config.url)) {
    serializeRequest(camelSerializer, response);
  }
  if (response.status === 401 && !!LocalStorage.getTokenManager()) {
    LocalStorage.removeTokenManager();
    window.location.replace(ROUTES.LOGIN_USER.path);
  }
});

api.addRequestTransform(request => {
  authorizeRequest(request);
  if (request.data) {
    serializeRequest(snakeSerializer, request);
  }
});

export default api;
