import i18next from 'i18next';

export const AUDIT_TYPES = { USER: 'user', PARAMETRIZATION: 'parametrization', CASE: 'case', RISK: 'risk' };

export const AUDIT_VALUES_PER_TYPE = {
  [AUDIT_TYPES.USER]: {},
  [AUDIT_TYPES.PARAMETRIZATION]: {
    SECTION: 'section'
  },
  [AUDIT_TYPES.CASE]: {},
  [AUDIT_TYPES.RISK]: {}
};

export const getAuditValuesPerType = type => ({
  CREATED_AT: 'createdAt',
  AUTHOR: 'author',
  ...AUDIT_VALUES_PER_TYPE[type],
  ACTIONS: 'actions'
});

export const getAuditHeadValues = type =>
  Object.values({ ...getAuditValuesPerType(type) }).map(auditValue => ({
    name: auditValue,
    title: i18next.t(`Audit:${auditValue}`)
  }));
