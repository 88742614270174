import React, { useMemo } from 'react';
import { arrayOf, bool, node, string, object } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import LocalStorage from '~services/LocalStorageService';
import Helmet from '~app/components/Helmet';
import { isInPublicOrInRestricted, getCurrentRouteInformation, shouldShowView } from '~models/routes';
import Navbar from '~app/components/Navbar';
import Menu from '~app/components/Menu';
import LoadingWrapper from '~app/components/LoadingWrapper';

import styles from './styles.module.scss';

const AuthenticatedRoute = ({
  title,
  description,
  path,
  supportedRoles,
  publicRoute,
  component: Component,
  componentProps,
  ...props
}) => {
  const tokenManager = LocalStorage.getTokenManager();
  const { pathname, search } = useLocation();
  const { logged, access, redirects } = useMemo(
    () =>
      getCurrentRouteInformation({
        tokenManager,
        pathname,
        search,
        supportedRoles,
        publicRoute
      }),
    [tokenManager, pathname]
  );

  const { settingsLoading, settings } = useSelector(state => state.settings);

  return (
    <LoadingWrapper loading={settingsLoading}>
      {isInPublicOrInRestricted(logged, access, publicRoute) ? (
        <Redirect to={redirects.logged} />
      ) : shouldShowView(logged, access, publicRoute) ? (
        <>
          <Helmet
            title={title || i18next.t('Title:title')}
            description={description}
            favicon={settings.favicon}
          />
          <Route
            path={path}
            render={() => (
              <>
                {!publicRoute && <Menu />}
                <div className={`${styles.contentContainer} ${publicRoute ? '' : styles.padded}`}>
                  {!publicRoute && <Navbar />}
                  <Component {...componentProps} />
                </div>
              </>
            )}
            {...props}
          />
        </>
      ) : (
        <Redirect to={redirects.notLogged} />
      )}
    </LoadingWrapper>
  );
};

AuthenticatedRoute.propTypes = {
  path: string.isRequired,
  component: node,
  // eslint-disable-next-line
  componentProps: object,
  description: string,
  publicRoute: bool,
  supportedRoles: arrayOf(string),
  title: string
};

export default withRouter(AuthenticatedRoute);
