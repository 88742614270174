import i18next from 'i18next';

i18next.addResources('es', 'InterventionsList', {
  fetchError: 'Ocurrió un error al obtener las intervenciones.',
  createIntervention: 'Crear Intervención',
  title: 'Título',
  createdAt: 'Fecha de Creación',
  type: 'Arista',
  actions: 'Acciones',
  noContent: 'No se encontraron intervenciones',
  view: 'Ver Intervención'
});
