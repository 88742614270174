import i18next from 'i18next';

i18next.addResources('es', 'Cases', {
  updateError: 'Ocurrió un error actualizando el caso',
  updateErrorCode4002: 'Hubo un error actualizando el caso: Debe haber al menos un profesional asignado.',
  updateSuccess: 'Caso actualizado correctamente',
  fetchError: 'Ocurrió un error buscando los casos',
  fetchSingleError: 'Ocurrió un error buscando el caso',
  edit: 'Editar'
});
