import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import actions from '~redux/Auth/actions';
import Button from '~components/Button';

import styles from './styles.module.scss';

function Navbar() {
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(actions.logOut());
  };

  const { currentUser } = useSelector(state => state.auth);

  return (
    <div className={styles.navbarContainer}>
      <nav className={`${styles.navbar} row middle end`}>
        <div className={styles.infoContainer}>
          <span className={styles.user}>{currentUser?.user?.name}</span>
          <div className={styles.divisor} />
          <Button className={`button-primary ${styles.logout}`} type="submit" onClick={handleLogOut}>
            {i18next.t('Navbar:logout')}
          </Button>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
