import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  sections: [],
  audit: [],
  updateSection: null,
  updateRisks: null
});

const reducerDescription = {
  primaryActions: [
    actions.GET_SECTIONS,
    actions.GET_SECTIONS_AUDIT,
    actions.UPDATE_SECTION,
    actions.UPDATE_RISKS
  ]
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
