import React, { Suspense, useEffect, lazy } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { history } from '~redux/store';
import { ROUTES } from '~constants/routes';
import AuthActions from '~redux/Auth/actions';
import Loading from '~components/LoadingWrapper/components/Loading';
import { AUDIT_TYPES } from '~app/screens/Audit/constants';

import styles from './styles.module.scss';
import AuthenticatedRoute from './components/AuthenticatedRoute';

const Home = lazy(() => import('../../screens/Home'));
const Login = lazy(() => import('../../screens/Login'));
const PasswordRecovery = lazy(() => import('../../screens/PasswordRecovery'));
const ForgotPassword = lazy(() => import('../../screens/ForgotPassword'));
const Parametrization = lazy(() => import('../../screens/Parametrization'));
const CaseCreation = lazy(() => import('../../screens/CaseCreation'));
const CaseSearch = lazy(() => import('../../screens/CaseSearch'));
const CaseEdition = lazy(() => import('../../screens/CaseEdition'));
const UsersList = lazy(() => import('../../screens/UsersList'));
const UserCreation = lazy(() => import('../../screens/UserCreation'));
const UserEdition = lazy(() => import('../../screens/UserEdition'));
const RiskManagement = lazy(() => import('../../screens/RiskManagement'));
const ReportCreation = lazy(() => import('../../screens/Report'));
const CaseVisualization = lazy(() => import('../../screens/CaseVisualization'));
const InterventionCreation = lazy(() => import('../../screens/InterventionCreation'));
const InterventionsList = lazy(() => import('../../screens/InterventionsList'));
const InterventionVisualization = lazy(() => import('../../screens/InterventionVisualization'));
const Audit = lazy(() => import('../../screens/Audit'));
const ReportAudit = lazy(() => import('../../screens/ReportAudit'));
const CaseReportAudit = lazy(() => import('../../screens/CaseReportAudit'));

const AppRoutesContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AuthActions.getCurrentUser());
  }, []);
  return (
    <ConnectedRouter history={history}>
      <div className={`column center ${styles.container} ${styles.containerAlgo}`}>
        <Suspense fallback={<Loading width={500} height={500} />}>
          <AuthenticatedRoute {...ROUTES.CASE_CREATION} component={CaseCreation} />
          <AuthenticatedRoute {...ROUTES.CASE_SEARCH} component={CaseSearch} />
          <AuthenticatedRoute {...ROUTES.PARAMETRIZATION} component={Parametrization} />
          <AuthenticatedRoute {...ROUTES.RISK_MANAGEMENT} component={RiskManagement} />
          <AuthenticatedRoute {...ROUTES.CASE_VISUALIZATION} component={CaseVisualization} />
          <AuthenticatedRoute {...ROUTES.CASE_INTERVENTION_CREATION} component={InterventionCreation} />
          <AuthenticatedRoute {...ROUTES.CASE_INTERVENTIONS} component={InterventionsList} />
          <AuthenticatedRoute {...ROUTES.CASE_INTERVENTION} component={InterventionVisualization} />
          <AuthenticatedRoute {...ROUTES.CASE_EDITION} component={CaseEdition} />
          <AuthenticatedRoute {...ROUTES.USERS_LIST} component={UsersList} />
          <AuthenticatedRoute {...ROUTES.USER_CREATION} component={UserCreation} />
          <AuthenticatedRoute {...ROUTES.USER_EDITION} component={UserEdition} />
          <AuthenticatedRoute {...ROUTES.REPORTS} component={ReportCreation} />
          <AuthenticatedRoute {...ROUTES.PASSWORD_RECOVERY} component={PasswordRecovery} />
          <AuthenticatedRoute {...ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
          <AuthenticatedRoute {...ROUTES.LOGIN_USER} component={Login} />
          <AuthenticatedRoute {...ROUTES.USER_AUDIT} component={() => <Audit type={AUDIT_TYPES.USER} />} />
          <AuthenticatedRoute
            {...ROUTES.PARAMETRIZATION_AUDIT}
            component={() => <Audit type={AUDIT_TYPES.PARAMETRIZATION} />}
          />
          <AuthenticatedRoute {...ROUTES.CASE_AUDIT} component={() => <Audit type={AUDIT_TYPES.CASE} />} />
          <AuthenticatedRoute {...ROUTES.RISK_AUDIT} component={() => <Audit type={AUDIT_TYPES.RISK} />} />
          <AuthenticatedRoute {...ROUTES.REPORTS_AUDIT} component={ReportAudit} />
          <AuthenticatedRoute {...ROUTES.CASE_REPORTS_AUDIT} component={CaseReportAudit} />
          <AuthenticatedRoute {...ROUTES.HOME} component={Home} exact />
        </Suspense>
      </div>
    </ConnectedRouter>
  );
};

export default AppRoutesContainer;
