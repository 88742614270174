import { push, replace } from 'connected-react-router';
import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import UsersServices from '~services/Users/service';
import { ROUTES } from '~constants/routes';
import { toastErrorFromInternalCode } from '~utils/toast';

import { isValidInternalCode } from './utils';

export const actions = createTypes(
  completeTypes(
    [
      'CREATE_USER',
      'ADMIN_CREATE_USER',
      'UPDATE_PASSWORD',
      'GET_USERS',
      'GET_PROFESSIONALS',
      'GET_USER',
      'GET_USER_AUDIT',
      'UPDATE_USER',
      'DELETE_USER',
      'FORGOT_PASSWORD'
    ],
    []
  ),
  '@@USERS'
);

const targets = {
  CREATE_USER: 'createUser',
  UPDATE_PASSWORD: 'updatePassword',
  USERS: 'users',
  PROFESSIONALS: 'professionals',
  UPDATE_USER: 'updateUser',
  DELETE_USER: 'deleteUser',
  USER: 'user',
  AUDIT: 'audit',
  FORGOT_PASSWORD: 'forgotPassword'
};

const actionCreators = {
  getUsers: query => ({
    type: actions.GET_USERS,
    target: targets.USERS,
    payload: query,
    service: UsersServices.getUsers
  }),
  getAudit: id => ({
    type: actions.GET_USER_AUDIT,
    target: targets.AUDIT,
    payload: id,
    service: UsersServices.getUserAudit
  }),
  getProfessionals: () => ({
    type: actions.GET_PROFESSIONALS,
    target: targets.PROFESSIONALS,
    service: UsersServices.getProfessionals
  }),
  createUser: ({ user, closeUrl }) => ({
    type: actions.CREATE_USER,
    target: targets.CREATE_USER,
    payload: user,
    service: UsersServices.createUser,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(replace(closeUrl));
      })
    ]
  }),
  getUser: id => ({
    type: actions.GET_USER,
    target: targets.USER,
    payload: id,
    service: UsersServices.getUser,
    injections: [
      withPostFailure(dispatch => {
        toast.error(i18next.t('UserEdition:getUserError'));
        dispatch(push(ROUTES.USERS_LIST.path));
      })
    ]
  }),
  updateUser: user => ({
    type: actions.UPDATE_USER,
    target: targets.UPDATE_USER,
    payload: user,
    service: UsersServices.updateUser,
    injections: [
      withPostSuccess(dispatch => {
        toast.success(i18next.t('UserEdition:updateUserSuccess'));
        dispatch(push(ROUTES.USERS_LIST.path));
      }),
      withPostFailure(
        toastErrorFromInternalCode(isValidInternalCode.edition, 'UserEdition', 'updateUserError')
      )
    ]
  }),
  deleteUser: id => ({
    type: actions.DELETE_USER,
    target: targets.DELETE_USER,
    payload: id,
    service: UsersServices.deleteUser,
    injections: [
      withPostSuccess(dispatch => {
        toast.success(i18next.t('UserEdition:deleteUserSuccess'));
        dispatch(push(ROUTES.USERS_LIST.path));
      }),
      withPostFailure(() => toast.error(i18next.t('UserEdition:deleteUserError')))
    ]
  }),
  adminCreateUser: ({ user, closeUrl }) => ({
    type: actions.ADMIN_CREATE_USER,
    target: targets.CREATE_USER,
    payload: user,
    service: UsersServices.adminCreateUser,
    injections: [
      withPostSuccess(dispatch => {
        toast.success(i18next.t('UserCreation:userCreated'));
        dispatch(replace(closeUrl));
      }),
      withPostFailure(
        toastErrorFromInternalCode(isValidInternalCode.creation, 'UserCreation', 'creationFailed')
      )
    ]
  }),
  updatePassword: ({ password, closeUrl }) => ({
    type: actions.UPDATE_PASSWORD,
    target: targets.UPDATE_PASSWORD,
    payload: password,
    service: UsersServices.updatePassword,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(replace(closeUrl));
        toast.success(i18next.t('PasswordRecoveryForm:success'));
      }),
      withPostFailure(() => {
        toast.error(i18next.t('PasswordRecoveryForm:error'));
      })
    ]
  }),
  forgotPassword: ({ email, closeUrl }) => ({
    type: actions.FORGOT_PASSWORD,
    target: targets.FORGOT_PASSWORD,
    payload: email,
    service: UsersServices.forgotPassword,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(replace(closeUrl));
        toast.success(i18next.t('ForgotPassword:success'));
      }),
      withPostFailure(() => {
        toast.error(i18next.t('ForgotPassword:error'));
      })
    ]
  })
};

export default actionCreators;
