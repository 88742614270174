import i18next from 'i18next';

i18next.addResources('es', 'ReportCreation', {
  title: 'Crear Reporte',
  createdAt: 'Rango de fechas',
  fields: 'Campos',
  creationFailed: 'Hubo un error con la creación del reporte.',
  isLeaving: 'Tenés cambios sin guardar. ¿Estás seguro que querés irte sin crear el reporte?',
  rangeRequired: 'Requerido',
  useAllFields: 'Utilizar todos los campos'
});
