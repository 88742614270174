import i18next from 'i18next';

i18next.addResources('es', 'CaseVisualization', {
  operator: 'Operador',
  professionals: 'Profesionales Asignados',
  state: 'Estado',
  interventions: 'Intervenciones',
  createdAt: 'Fecha de creación',
  viewReports: 'Ver Informes',
  updatedAt: 'Fecha de última actualización',
  editCase: 'Ver datos',
  notes: 'Notas',
  pending: 'Pendiente de asignación',
  open: 'Abierto',
  goToInterventions: 'Ir A Intervenciones',
  closed: 'Cerrado',
  unassignText: '¿Estás seguro/a que querés desasignarte del caso?',
  createReportText: 'Generación de Informe',
  noProfessionalsAssigned: 'No hay profesionales asignados.',
  isLeavingAssignment: 'Tenés profesionales asignados sin guardar. ¿Estás seguro/a que querés irte?',
  changeStateFrom: '¿Estás seguro/a que querés $t(CaseVisualization:{{value}}Action) el caso?',
  toFormText: 'Nombre Destinatario',
  labeledsFormText: 'Autos Caratulados',
  titleFormText: 'Título',
  introductionFormText: 'Introducción',
  commentsFormText: 'Comentarios u observaciones',
  positionFormText: 'Cargo Destinatario',
  institutionFormText: 'Institución Destinatario',
  openAction: 'cerrar',
  closedAction: 'reabrir',
  unassignSelf: 'Desasignarme',
  unassignMotive: 'Indicá el motivo:',
  required: 'requerido',
  doesNotCorrespond: 'No corresponde',
  createReport: 'Crear Informe',
  professionalsNotCorrespond: 'No corresponde asignación.'
});
