import i18next from 'i18next';

i18next.addResources('es', 'Home', {
  createCase: 'Crear caso',
  createReport: 'Crear reporte',
  noContent: 'No hay casos $t(Home:{{role}})',
  usersList: 'Listado de usuarios',
  parametrization: 'Parametrización',
  riskParametrization: 'Manejo de Riesgos',
  titleFor: 'Casos $t(Home:{{role}})',
  operator: 'pendientes de asignación',
  professional: 'asignados',
  supervisor: 'abiertos',
  admin: 'abiertos'
});
