import i18next from 'i18next';

i18next.addResources('es', 'UsersTable', {
  name: 'Nombre',
  surname: 'Apellido',
  email: 'Email',
  role: 'Rol',
  dni: 'DNI',
  actions: 'Acciones',
  edit: 'Editar',
  admin: 'Administrador/a',
  operator: 'Operador/a',
  supervisor: 'Supervisor/a',
  professional: 'Profesional',
  searchPlaceholder: 'Buscar...',
  noContent: 'No hay coincidencias',
  search: 'Buscar'
});
