import i18next from 'i18next';

i18next.addResources('es', 'CaseSearch', {
  search: 'Buscar',
  searchPlaceholder: 'Ingrese el valor del campo...',
  searchedField: 'Valor Buscado',
  createdAt: 'Fecha de creación',
  actions: 'Acciones',
  noContent: 'No hay casos que coincidan con la búsqueda',
  creationDate: 'Fecha de creación:',
  operator: 'Operador/a:',
  viewCase: 'Ver Caso',
  required: 'Este campo es requerido'
});
