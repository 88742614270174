import i18next from 'i18next';

i18next.addResources('es', 'Select', {
  search: 'Buscar...',
  noMatches: 'No hay coincidencias'
});

i18next.addResources('en', 'Select', {
  search: 'Search...',
  noMatches: 'No matches'
});
