/* eslint-disable react/button-has-type */
import { func, oneOf, string, bool, objectOf } from 'prop-types';
import React from 'react';

import LoadingWrapper from '../LoadingWrapper';

function Button({
  onClick,
  type = 'button',
  className,
  containerClassName,
  loading,
  loadingClassName,
  loadingProps,
  disabled,
  children
}) {
  return (
    <div className={containerClassName}>
      <LoadingWrapper loading={loading} className={loadingClassName} loadingProps={loadingProps}>
        <button type={type} className={className} onClick={onClick} disabled={disabled}>
          {children}
        </button>
      </LoadingWrapper>
    </div>
  );
}

Button.propTypes = {
  className: string,
  containerClassName: string,
  disabled: bool,
  loading: bool,
  loadingClassName: string,
  loadingProps: objectOf(string),
  type: oneOf(['button', 'submit', 'reset']),
  onClick: func
};

export default Button;
