import i18next from 'i18next';

i18next.addResources('es', 'Field', {
  required: 'Requerido',
  optionsRequired: 'Debe haber al menos una respuesta posible',
  freeType: 'Respuesta Libre',
  simpleType: 'Selección Simple',
  multipleType: 'Selección Múltiple',
  numericType: 'Numérico',
  dateType: 'Fecha',
  timeType: 'Hora',
  response: 'Respuesta',
  addOption: '+ Agregar respuesta',
  newField: 'Nuevo Campo'
});
