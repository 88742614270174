import Immutable from 'seamless-immutable';
import { createReducer, onSetValue } from 'redux-recompose';

import { actions } from './actions';
import { MODALS } from './constants';

const defaultState = {
  [MODALS.DELETE_USER]: false,
  [MODALS.DELETE_ASSIGNED_PROFESSIONAL]: false
};

const reducerDescription = {
  [actions.OPEN_MODAL]: onSetValue(true),
  [actions.CLOSE_MODAL]: onSetValue(false),
  [actions.CLEAR_MODALS]: () => new Immutable({})
};

const reducer = createReducer(new Immutable(defaultState), reducerDescription);

export default reducer;
