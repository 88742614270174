import { VALID_INTERNAL_CODES } from './constants';

const isValidCode = validInternalCodes => internalCode =>
  validInternalCodes.some(code => code === internalCode);

export const isValidInternalCode = {
  creation: isValidCode(VALID_INTERNAL_CODES.CREATION),
  edition: isValidCode(VALID_INTERNAL_CODES.EDITION),
  case: isValidCode(VALID_INTERNAL_CODES.CASE)
};
