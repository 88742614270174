import i18next from 'i18next';

i18next.addResources('es', 'InterventionVisualization', {
  fetchError: 'Hubo un error obteniendo la intervención del caso.',
  professional: 'Profesional',
  title: 'Título',
  description: 'Descripción',
  type: 'Arista',
  files: 'Archivos',
  state: 'Estado',
  createdAt: 'Fecha de creación',
  pending: 'Pendiente de aprobación',
  created: 'Creada'
});
