import { A4_SIZE } from '~app/screens/Report/constants';
import { buildDateES } from '~utils/date';

import { BULLET_POINT, FONT_SIZE, FOOTER_HEIGHT, LINE_PADDING, PADDING } from '../constants';

const fontSizePerText = {
  title: 18,
  commentsTitle: 14,
  interventionsTitle: 14
};
const alignPerText = {
  title: 'center'
};

const xPointPerKey = {
  title: A4_SIZE.WIDTH / 2,
  date: A4_SIZE.WIDTH - PADDING * 3
};

const plusHeightPerKey = {
  interventionsTitle: LINE_PADDING,
  commentsTitle: LINE_PADDING
};

const FULL_WIDTH = A4_SIZE.WIDTH - PADDING * 2;
const HALF_WIDTH = FULL_WIDTH / 2;

const shouldAddUnderline = key =>
  ['title', 'commentsTitle', 'interventionsTitle'].some(keyWithUnderline => keyWithUnderline === key);

const setPdfSizePerKey = (key, pdf) =>
  ({
    title: pdfDoc => pdfDoc.setFontSize(fontSizePerText.title)
  }[key]?.(pdf) || pdf.setFontSize(FONT_SIZE));

const getMaxWidthPerKey = key =>
  ({
    to: HALF_WIDTH,
    position: HALF_WIDTH,
    institution: HALF_WIDTH
  }[key] || FULL_WIDTH);

export const getSplittedTexts = (obj, pdf) =>
  Object.keys(obj).map(key => ({
    text: () => {
      setPdfSizePerKey(key, pdf);
      return pdf.splitTextToSize(obj[key], getMaxWidthPerKey(key));
    },
    size: fontSizePerText[key] || FONT_SIZE,
    xPoint: xPointPerKey[key] || PADDING,
    align: alignPerText[key] || 'left',
    plusHeight: plusHeightPerKey[key] || 0,
    addUnderline: shouldAddUnderline(key)
  }));

export const mapInterventionsToTexts = interventions =>
  interventions.reduce(
    (prevInterventions, intervention, index) => ({
      ...prevInterventions,
      [`interventionText${index}`]: `${BULLET_POINT} ${buildDateES(new Date(intervention.createdAt))}: ${
        intervention.title
      }: ${intervention.description}`
    }),
    {}
  );

export const addFooterToPage = pdf => {
  pdf.setDrawColor(187, 187, 187);
  pdf.line(0, A4_SIZE.HEIGHT - 8, A4_SIZE.WIDTH, A4_SIZE.HEIGHT - 8);
  pdf.text(`${pdf.internal.getNumberOfPages()}`, A4_SIZE.WIDTH - PADDING, A4_SIZE.HEIGHT - 2, 'center');
};

const underlineHeight = (height, index) => height + LINE_PADDING * index - 2.5;

const yPosition = (height, plusHeight, index) => height + plusHeight + LINE_PADDING * index;

export const writeTexts = (splittedTexts, pdf) => {
  let heightUsed = PADDING;
  let textInPageIndex = 0;

  splittedTexts.forEach(({ text: getText, size, align, xPoint, addUnderline, plusHeight }) => {
    const textArray = getText();
    pdf.setFontSize(size);
    const { w: widthTextArray } = pdf.getTextDimensions(textArray);

    textArray.forEach(text => {
      const textToAdd = text === '' ? '\n' : text;
      const { h: textHeight } = pdf.getTextDimensions(textToAdd);

      const height = textHeight + plusHeight;

      if (height > A4_SIZE.HEIGHT - heightUsed - FOOTER_HEIGHT - LINE_PADDING * textInPageIndex) {
        addFooterToPage(pdf);
        pdf.addPage();
        heightUsed = PADDING;
        textInPageIndex = 0;
      }

      pdf.text(textToAdd, xPoint, yPosition(heightUsed, plusHeight, textInPageIndex), align);

      heightUsed += height;
    });

    if (addUnderline) {
      const underlineHeightValue = underlineHeight(heightUsed, textInPageIndex);
      if (align === 'center') {
        [xPoint + widthTextArray / 2, xPoint - widthTextArray / 2].forEach(point =>
          pdf.line(xPoint, underlineHeightValue, point, underlineHeightValue)
        );
      } else {
        pdf.line(
          xPoint,
          underlineHeight(heightUsed, textInPageIndex),
          widthTextArray + PADDING,
          underlineHeight(heightUsed, textInPageIndex)
        );
      }
    }
    textInPageIndex++;
  });
};
