import { withPostSuccess, withPostFailure } from 'redux-recompose';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import casesService from '~services/Cases/service';
import { isValidInternalCode } from '~redux/Users/utils';
import { toastErrorFromInternalCode } from '~utils/toast';

import actionCreators from './actions';

export const updateStateCaseAction = (action, target, service = casesService.updateStateCase) => state => ({
  type: action,
  target,
  service,
  payload: state,
  injections: [
    withPostSuccess(dispatch => {
      dispatch(actionCreators.getCase(state.id, { shouldPopulate: true }));
      return toast.success(i18next.t('Cases:updateSuccess'));
    }),
    withPostFailure(toastErrorFromInternalCode(isValidInternalCode.case, 'Cases', 'updateError'))
  ]
});
