import i18next from 'i18next';

i18next.addResources('es', 'UserCreation', {
  required: 'Requerido',
  invalidDNI: 'DNI inválido. Debe ser entre los números 10000000 y 99999999',
  invalidEmail: 'Email inválido.',
  nonNumeric: 'Ingresar sólo números.',
  notInteger: 'El número ingresado no es un DNI correcto. Ingresar sólo números sin puntos.',
  valueNotTrimmed: 'No ingresar sólo espacios.',
  title: 'Crear Usuario/a',
  isLeaving: 'Tenés cambios del usuario sin guardar. ¿Estás seguro que querés irte?',
  name: 'Nombre/s',
  surname: 'Apellido/s',
  dni: 'DNI',
  email: 'Email',
  role: 'Rol',
  password: 'Contraseña',
  userCreated: 'Se creó el usuario correctamente.',
  creationFailed: 'Hubo un error con la creación del usuario.',
  creationFailedCode1031: 'Ya hay un usuario con el email ingresado. Por favor, ingresar otro.',
  creationFailedCode1033: 'Ya hay un usuario con el DNI ingresado. Por favor, ingresar otro.'
});
