import i18next from 'i18next';

i18next.addResources('es', 'CaseRisk', {
  level: 'Riesgo: $t(CaseRisk:{{level}})',
  none: 'Ninguno',
  low: 'Bajo',
  medium: 'Medio',
  high: 'Alto',
  veryHigh: 'Muy Alto'
});
