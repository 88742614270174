import React, { useState } from 'react';
import i18next from 'i18next';
import cn from 'classnames';
import { string, func, shape } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import ArrowDown from '~assets/arrow-down.svg';
import { routePropTypes } from '~propTypes/routes';

import styles from './styles.module.scss';

function MenuItem({ item, onClick, itemClassName = '', iconClassName = '', labelClassName = '' }) {
  const location = useLocation();
  const [isHovering, setHovering] = useState(false);

  const pathMatches = val => val.route?.path === location?.pathname;
  const subMenuSelected = item.subMenus?.some(pathMatches);
  const [isOpen, setIsOpen] = useState(subMenuSelected);

  const toggleOpen = () => setIsOpen(!isOpen);
  const handleClick = () => {
    onClick?.(item);
    setIsOpen(!isOpen);
  };
  const setHover = state => () => setHovering(state);

  const isSelected = pathMatches(item);
  const ItemComponent = item?.subMenus?.length ? 'button' : Link;
  return (
    <ItemComponent to={item.route?.path} className="column full-width" onClick={handleClick}>
      <div
        onMouseEnter={setHover(true)}
        onMouseLeave={setHover(false)}
        className={cn(
          'row middle full-width',
          {
            [styles.menuItemSelected]: isSelected,
            [styles.menuItemNotSelected]: !isSelected,
            [styles.menuItemHovered]: isHovering
          },
          itemClassName
        )}>
        <img
          className={cn('m-right-2', styles.menuIcon, iconClassName)}
          src={isSelected || isHovering ? item.iconOn : item.iconOff}
          alt={i18next.t(`Menu:${item.name}`)}
        />
        <div className="row middle full-width space-between">
          <h2 className={`small-text ${labelClassName}`}>{i18next.t(`Menu:${item.name}`)}</h2>
          {item.subMenus && (
            <img
              src={ArrowDown}
              className={cn(styles.arrow, { [styles.rotate]: isOpen })}
              onClick={toggleOpen}
            />
          )}
        </div>
      </div>
      {item.subMenus && isOpen && (
        <div className={`full-width ${styles.subMenuContainer}`}>
          {item.subMenus?.map(sub => (
            <MenuItem
              key={sub.name}
              item={sub}
              itemClassName={styles.subItem}
              iconClassName={styles.subItemIcon}
              labelClassName="smaller-text"
            />
          ))}
        </div>
      )}
    </ItemComponent>
  );
}
MenuItem.propTypes = {
  item: shape({
    name: string.isRequired,
    iconOn: string.isRequired,
    iconOff: string.isRequired,
    route: routePropTypes
  }).isRequired,
  iconClassName: string,
  itemClassName: string,
  labelClassName: string,
  onClick: func
};
export default MenuItem;
