import i18next from 'i18next';
import { toast } from 'react-toastify';
import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import sectionsService from '~services/Sections/service';

export const actions = createTypes(
  completeTypes(['GET_SECTIONS', 'GET_SECTIONS_AUDIT', 'UPDATE_SECTION', 'UPDATE_RISKS'], []),
  '@@SECTIONS'
);

export const targets = {
  SECTIONS: 'sections',
  SECTIONS_AUDIT: 'audit',
  UPDATE_SECTION: 'updateSection',
  UPDATE_RISKS: 'updateRisks'
};

const actionCreators = {
  getSections: () => ({
    type: actions.GET_SECTIONS,
    target: targets.SECTIONS,
    service: sectionsService.getSections
  }),
  getSectionsAudit: () => ({
    type: actions.GET_SECTIONS_AUDIT,
    target: targets.SECTIONS_AUDIT,
    service: sectionsService.getAudit
  }),
  updateSections: sections => ({
    type: actions.UPDATE_SECTION,
    target: targets.UPDATE_SECTION,
    service: sectionsService.updateSections,
    payload: sections,
    injections: [
      withPostSuccess(() => toast.success(i18next.t('Section:updateSuccess'))),
      withPostFailure(() => toast.error(i18next.t('Section:updateError')))
    ]
  }),
  updateRisks: sectionsWithRisks => ({
    type: actions.UPDATE_RISKS,
    target: targets.UPDATE_RISKS,
    service: sectionsService.updateRisks,
    payload: sectionsWithRisks,
    injections: [
      withPostSuccess(dispatch => {
        toast.success(i18next.t('Section:updateSuccess'));
        return dispatch(actionCreators.getSections());
      }),
      withPostFailure(() => toast.error(i18next.t('Section:updateError')))
    ]
  })
};

export default actionCreators;
